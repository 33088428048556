import React, { FunctionComponent } from 'react';

import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { PrimaryLayout } from 'components/PrimaryLayout';
import { RichText } from 'components/RichText';
import { Icon } from 'components/Icon';
import { TableOfContentsLink } from 'components/TableOfContentsLink';

import { SEO } from 'components/SEO';
import website from '../../../config/website.js';

import './Legal.scss';

export const query = graphql`
  query ($slug: String!) {
    contentfulLegal(slug: { eq: $slug }) {
      body {
        raw
      }
      createdAt(formatString: "MMMM Do, YYYY")
      label
      slug
      metaDescription
    }
  }
`;

type LegalProps = {
  data: any;
};

const Legal: FunctionComponent<LegalProps> = ({ data }) => {
  const location = useLocation();
  const sections = documentToReactComponents(
    JSON.parse(data.contentfulLegal.body.raw)
  )
    ?.filter((el) => el.type === 'h2')
    .map((el) => el.props.children[0]);

  const scrollToSection: (id: string) => void = (id) => {
    let absoluteElementTop = 100;
    if (typeof document !== undefined) {
      const element = document.getElementById(id);
      if (element) {
        const elementRect = element.getBoundingClientRect();
        // Subtract height of the NavBar
        absoluteElementTop = elementRect.top + window.pageYOffset - 72;
      }
    }
    return window.scrollTo(0, absoluteElementTop);
  };

  return (
    <PrimaryLayout>
      <SEO
        title={`${data.contentfulLegal.label} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={data.contentfulLegal.metaDescription}
        date={data.contentfulLegal.createdAt}
        article
      />
      <div className="outer-legal-page-container ">
        <div className="legal-page-container">
          <h1 className="title" data-cy="legal-title">
            {data.contentfulLegal.label}
          </h1>

          <p className="date">
            <Icon name="calendar" alt="calendar icon" /> Last Updated:{' '}
            {data.contentfulLegal.createdAt}
          </p>
          {sections.map((sectionName: string, index: number) => {
            return (
              <TableOfContentsLink
                key={sectionName}
                className="section-link"
                index={index + 1}
                sectionName={sectionName}
                onClick={() => scrollToSection(sectionName)}
              />
            );
          })}
          <RichText isSinglePage body={data.contentfulLegal.body} />
        </div>
      </div>
    </PrimaryLayout>
  );
};

export default Legal;
