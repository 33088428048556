import React, { FunctionComponent } from 'react';

import './TableOfContentsLink.scss';
import { Charts } from '../Charts';
export type TableOfContentsLinkProps = {
  className?: string;
  sectionName: string;
  onClick: () => void;
  index: number;
};

const TableOfContentsLink: FunctionComponent<TableOfContentsLinkProps> = ({
  className,
  sectionName,
  onClick,
  index,
}) => {
  return (
    <button
      onClick={onClick}
      className={`table-of-contents-link-container ${className}`}
      data-cy="table-of-contents-link"
    >
      <Charts className="icon" index={index} />
      <h2 className="section-name">{sectionName}</h2>
    </button>
  );
};

export default TableOfContentsLink;
